
import { defineComponent, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  ElInput,
  FormInstance,
  FormRules,
  ElForm,
  ElFormItem,
} from 'element-plus'

import { apiErrorNotification } from '@/core/helpers'
import { routeNames } from '@/router'
import { authStore } from '@/store'
import { usePermissions } from '@/core/compositions'

export default defineComponent({
  name: 'LoginView',

  components: { ElInput, ElForm, ElFormItem },

  setup() {
    const router = useRouter()

    const { defaultRedirectRoute } = usePermissions()

    const loading = ref(false)

    const formRef = ref<FormInstance>()
    const formValues = reactive({
      email: '',
      password: '',
    })
    const formValidationRules = reactive<FormRules>({
      email: [
        {
          required: true,
          message: 'Email address is required!',
          trigger: 'change',
        },
        {
          type: 'email',
          message: 'Invalid email address!',
          trigger: 'change',
        },
      ],
      password: [
        {
          required: true,
          message: 'Password is required!',
          trigger: 'change',
        },
        {
          min: 10,
          max: 64,
          message: 'Password length should be 10 to 64',
          trigger: 'change',
        },
      ],
    })

    const onSubmit = async () => {
      const isFormValid = await formRef.value?.validate()
      if (!isFormValid) {
        return false
      }

      loading.value = true

      try {
        await authStore.login(formValues)

        if (authStore.user?.isPassword === false) {
          router.push({ name: routeNames.setPassword })
        } else {
          router.push({ name: defaultRedirectRoute.value })
        }
      } catch (error) {
        apiErrorNotification(error)
      } finally {
        loading.value = false
      }
    }

    const toForgotView = () => {
      router.push({ name: routeNames.forgot })
    }

    return {
      formRef,
      formValues,
      formValidationRules,
      loading,
      onSubmit,
      toForgotView,
    }
  },
})
